:root {
    --color-primary: #3B5075;
    --color-secondary: #378a81;
    --color-text-default: #3B5075;
    --color-text-muted: #868E96;

    --color-default: #3B5075;
    --color-white: #FFF;
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #BEC2C6;
    --color-dark: #343A40;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #303f60;
    --color-secondary-dark: #2d685f;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #303f60;

    --color-primary-light: #F1F6FD;
    --color-secondary-light: #F3F9F8;
    --color-success-light: rgba(40, 167, 69, 0.62);
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3a5881;

    --font-default: "GT-America-Standard-Regular", sans-serif;
    --font-default-bold: "GT-Super-Text-Bold", sans-serif;
    --font-default-medium: "GT-America-Standard-Medium", sans-serif;

    --font-size-default: calc(20rem/16);
}
